<template>
  <div class="profile">
    <!--  user

    profile section-->
    <section class="profile-section">

      <div class="profile-left">
        <div class="profile-left-1">
          <v-avatar size="120">
            <img
                :src="`https://royal-io.imgix.net/${profileInfo.avatarAsset.filename}`"
                alt="profile-avatar">
          </v-avatar>
          <p>{{ profileInfo.name }}</p>
        </div>
        <div class="profile-left-2">
          <p>{{ profileInfo.owner.wallets[1].tokens.edges.length }}</p>
          <p>COLLECTION</p>
        </div>
      </div>
      <div class="profile-right">
        <div class="profile-right-1">
          <p>
            @{{ profileInfo.slug }}
          </p>
          <p @click="makeToast(), copyAddress()">
            {{ reduceAddress(profileInfo.owner.wallets[1].address) }}
          </p>
        </div>
      </div>
    </section>

    <!--    user collection section-->
    <section class="user-collection-section">
      <p>COLLECTION</p>
      <div class="recent-drops-cards">
        <card-drop
            v-for="(token, i) in profileInfo.owner.wallets[1].tokens.edges"
            :key="i"
            :artworkImg="token.node.artworkAsset.filename"
            :tier="token.node.tier.type"
            :avatarImg=" !token.node.wallet.user ? null : token.node.wallet.user.profiles[0].avatarAsset"
            :userName=" !token.node.wallet.user ? token.node.wallet.address : !token.node.wallet.user.profiles[0].avatarAsset ? token.node.wallet.address : token.node.wallet.user.profiles[0].slug"
            :editionTitle="token.node.tier.edition.rightsAsset.title"
            :editionType="token.node.tier.edition.rightsAsset.type"
            :maxSupply="token.node.tier.maxSupply"
            :artist="token.node.tier.edition.contributors[0].profile.name"
            @click="goPage('token',i)"/>
      </div>
    </section>
  </div>
</template>

<script>
import CardDrop from "@/components/Card/CardDrop";

export default {
  name: 'UserProfile',
  components: {
    CardDrop,
  },
  data() {
    return {
      profileInfo: {},
      tokens: [],
    }
  },
  async mounted() {
    await this.userprofileData()
  },
  methods: {
    async userprofileData() {
      await this.$http.get('/userprofile'
      ).then(res => {
        this.profileInfo = res.data.profile
        this.tokens = this.profileInfo.owner.wallets[1].tokens.edges
      }).catch
      ((err) => {
        console.log(err)
      })
    },
    goPage(name, index) {
      this.$router.push({
        name: name,
        params: {
          tokenId: this.tokens[index].node.royalId,
          ownerAvatarImg: !this.tokens[index].node.wallet.user ? null : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? null : this.tokens[index].node.wallet.user.profiles[0].avatarAsset.filename  ,
          ownerName: !this.tokens[index].node.wallet.user ? this.tokens[index].node.wallet.address : !this.tokens[index].node.wallet.user.profiles[0].avatarAsset ? this.tokens[index].node.wallet.address : this.tokens[index].node.wallet.user.profiles[0].slug,
        }
      })
    },
    reduceAddress(value) {
      return value.slice(0, 6) + '...' + value.slice(38, 42)
    },
    makeToast() {
      this.$toast("COPIED")
    },
    copyAddress() {
      this.$copyText(this.profileInfo.owner.wallets[1].address)
          .then(() => {
          })
          .catch(err => {
            console.log(err);
          })
    },
  }
}
</script>